<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Configurazione Badge
    </template>
    <template #body>
      <div class="mb-2">
        <b-form-checkbox v-model="configBadge.showName" name="check-button" switch>
          Mostra nome
        </b-form-checkbox>
      </div>
      <div class="mb-2">
          <b-form-checkbox v-model="configBadge.showEmail" name="check-button" switch>
            Mostra email
          </b-form-checkbox>
      </div>
      <div class="mb-2">
        <b-form-checkbox v-model="configBadge.showMobilePhone" name="check-button" switch>
          Mostra telefono
        </b-form-checkbox>
      </div>
      <div class="mb-2">
        <b-form-checkbox v-model="configBadge.showImage" name="check-button" switch>
          Mostra Immagine
        </b-form-checkbox>
      </div>
      <div class="mb-2">
        <b-form-checkbox v-model="configBadge.showFeedback" name="check-button" switch>
          Ricezione feedback da badge pubblico
        </b-form-checkbox>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalBadgeSetting',
  components: { ModalWrapper },
  props: {
    id: String,
    updateFlag: Function,
  },
  computed: {
    score() {
      return this.$store.getters['score/getCurrentScore'] || {};
    },
    configBadge() {
      return this.score.configBadge || {};
    },
  },
};
</script>

<style scoped>

</style>
